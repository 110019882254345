define('ember-kernel/error-manager/consumer/sentry-consumer/sentry-consumer', ['exports', './../base-consumer', './../../../tools/random', './../../../tools/merge', './../../loggable-error'], function (exports, _baseConsumer, _toolsRandom, _toolsMerge, _loggableError) {
  'use strict';

  exports['default'] = _baseConsumer['default'].extend({
    id: null,

    appProperties: null,

    appContext: null,

    initialized: false,

    init: function init(options) {

      _baseConsumer['default'].superclass.prototype.init.apply(this, arguments);

      try {
        Raven.config(options.sentryUrl, {
          fetchContext: true
        });
        this.id = _toolsRandom['default'].randomString();
        Raven.setUserContext({
          id: this.id
        });

        this.initialized = true;
      } catch (e) {
        console.error(e);
      }
    },

    storeAppContext: function storeAppContext(props) {
      if (this.initialized) {
        props = typeof props === 'object' ? props : {};
        this.appContext = (0, _toolsMerge['default'])(this.appContext || {}, props);

        Raven.setUserContext(this.appContext);
      }
    },

    clearAppContext: function clearAppContext() {
      if (this.initialized) {
        this.appContext = {
          id: this.appContext.id
        };

        Raven.setUserContext(this.appContext);
      }
    },

    consume: function consume(loggableError) {
      if (this.initialized) {
        console.warn('Error 2 Sentry:', loggableError.getPlainText());

        var data = loggableError.extractAdditionalData();
        if (data) {
          console.warn('Data 2 Sentry', data);
        }

        Raven.captureAdvancedException(loggableError.error, {
          extra: loggableError.extractAdditionalData()
        });
      }
      return true;
    },

    logMessage: function logMessage(data) {
      if (this.initialized) {
        var message = data.message;
        var additionalData = data.additionalData;
        var level = data.level;

        console.warn('message to be sent to sentry:', message, additionalData, level);

        Raven.captureMessage(message, {
          extra: additionalData,
          level: level
        });
      }
    }

  });
});