define("ember-kernel/application-loader/resolver/javascript-resolver", ["exports", "./element-resolver"], function (exports, _elementResolver) {
  "use strict";

  exports["default"] = _elementResolver["default"].extend({

    createElement: function createElement() {
      var ele = document.createElement("script");
      ele.type = "text/javascript";
      ele.src = this.url + this.postFix;

      return ele;
    }
  });
});