define('ember-kernel/application-loader/resolver/stylesheet-resolver', ['exports', './element-resolver'], function (exports, _elementResolver) {
  'use strict';

  exports['default'] = _elementResolver['default'].extend({

    createElement: function createElement() {
      var v = this.version ? '?' + this.version : '';
      var ele = document.createElement("link");
      ele.rel = 'stylesheet';
      ele.type = 'text/css';
      ele.href = this.url + this.postFix;

      return ele;
    }

  });
});