define("ember-kernel/tools/random", ["exports"], function (exports) {
    "use strict";

    exports["default"] = {

        randomString: function randomString() {
            var min = arguments.length <= 0 || arguments[0] === undefined ? 5 : arguments[0];
            var max = arguments.length <= 1 || arguments[1] === undefined ? 20 : arguments[1];
            var possible = arguments.length <= 2 || arguments[2] === undefined ? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" : arguments[2];

            var length = this.randomInt(min, max);
            var text = '';

            for (var i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return text;
        },

        randomInt: function randomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

    };
});