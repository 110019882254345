define('ember-kernel/error-manager/consumer/console-consumer/console-consumer', ['exports', './../base-consumer'], function (exports, _baseConsumer) {
  'use strict';

  exports['default'] = _baseConsumer['default'].extend({

    consume: function consume(error) {
      console.error(error.getPlainText());

      var data = error.extractAdditionalData();
      if (data) {
        console.warn('Error additional data', data);
      }

      return true;
    }

  });
});