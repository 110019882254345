define('ember-kernel/kernel/kernel', ['exports', './object', './../tools/ready'], function (exports, _object, _toolsReady) {
  'use strict';

  exports['default'] = _object['default'].extend({

    modules: [],

    name: 'Kernel',

    resourcesUrl: null,

    resources: null,

    triggerKernelEvent: function triggerKernelEvent(event, data) {
      for (var k in this.modules) {
        if (this.modules.hasOwnProperty(k)) {
          var obj = this.modules[k];
          obj.onKernelEvent(event, data);
        }
      }
    },

    getModule: function getModule(name) {

      var m = this.modules[name];
      if (!m) {
        throw new Error('Requested module %name is not registered'.replace('%name', name));
      }
      return m;
    },

    resolveResources: function resolveResources() {

      if (this.resourcesUrl && !this.resources) {
        return new RSVP.Promise((function (resolve, reject) {
          $.ajax({
            url: this.resourcesUrl,
            method: 'GET',
            dataType: 'JSON'
          }).error(function () {
            resolve();
          }).success((function (data) {
            this.resources = data;
            resolve();
          }).bind(this));
        }).bind(this));
      }
    },

    setup: function setup() {
      window[this.name] = this;
      Error.stackTraceLimit = 50;
    },

    load: function load() {
      for (var k in this.modules) {
        if (this.modules.hasOwnProperty(k)) {
          var obj = this.modules[k];
          obj.load(this);
        }
      }
    },

    run: function run() {
      return (0, _toolsReady['default'])((function () {
        return RSVP.resolve().then(this.resolveResources.bind(this)).then(this.setup.bind(this)).then(this.load.bind(this));
      }).bind(this));
    }

  });
});