define('ember-kernel/application-loader/renderer/webrtc-renderer/webrtc-renderer', ['exports', 'ember-kernel/application-loader/renderer/abstract-renderer', './webrtc-renderer.hbs', './style.hbs'], function (exports, _emberKernelApplicationLoaderRendererAbstractRenderer, _webrtcRendererHbs, _styleHbs) {
    'use strict';

    exports['default'] = _emberKernelApplicationLoaderRendererAbstractRenderer['default'].extend({

        showLoader: function showLoader(kernel) {
            $('head').append((0, _styleHbs['default'])({ resources: kernel.resources }));
            $('body').append((0, _webrtcRendererHbs['default'])({ resources: kernel.resources }));
            $('.cs-loading-page').fadeIn(500);
        },

        showLoaderText: function showLoaderText(text, kernel) {
            $('.cs-loading-page .cs-startup-text').text(text);
        },

        hideLoader: function hideLoader(duration) {
            $(".cs-loading-page").fadeOut(750, function () {
                $(".cs-loading-style").remove();
                $(".cs-loading-page").remove();
            });
        }

    });
});