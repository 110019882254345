define("ember-kernel/error-manager/consumer/production-consumer/production-error-page.hbs", ["exports", "handlebars.runtime"], function (exports, _handlebarsRuntime) {
    "use strict";

    exports["default"] = _handlebarsRuntime["default"]["default"].template({ "1": function _(container, depth0, helpers, partials, data) {
            var helper;

            return "            <div class=\"error-description\">\n                " + container.escapeExpression((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing, typeof helper === "function" ? helper.call(depth0, { "name": "description", "hash": {}, "data": data }) : helper)) + "\n            </div>\n";
        }, "3": function _(container, depth0, helpers, partials, data, blockParams) {
            var stack1;

            return "            <span class=\"error-page-button\">\n                <a id=" + container.escapeExpression(container.lambda((stack1 = blockParams[0][0]) != null ? stack1.id : stack1, depth0)) + ">\n                    " + container.escapeExpression(container.lambda((stack1 = blockParams[0][0]) != null ? stack1.text : stack1, depth0)) + "\n                </a>\n            </span>\n";
        }, "compiler": [7, ">= 4.0.0"], "main": function main(container, depth0, helpers, partials, data, blockParams) {
            var stack1, helper;

            return "<div class=\"error-page\">\n\n	<div class=\"logo\">\n        <img src=\"" + ((stack1 = container.lambda((stack1 = depth0 != null ? depth0.resources : depth0) != null ? stack1.logo : stack1, depth0)) != null ? stack1 : "") + "\" />\n    </div>\n\n	<div class=\"error-banner\">\n		<strong>ERROR</strong>\n	</div>\n\n	<div class=\"error-message\">\n		<div class=\"error-claim\">\n			Oh! Looks like something went wrong.\n		</div>\n\n        <div class=\"error-text\">\n            " + container.escapeExpression((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helpers.helperMissing, typeof helper === "function" ? helper.call(depth0, { "name": "message", "hash": {}, "data": data, "blockParams": blockParams }) : helper)) + "\n        </div>\n\n" + ((stack1 = helpers["if"].call(depth0, depth0 != null ? depth0.description : depth0, { "name": "if", "hash": {}, "fn": container.program(1, data, 0, blockParams), "inverse": container.noop, "data": data, "blockParams": blockParams })) != null ? stack1 : "") + "\n	</div>\n\n	<div class=\"button\">\n        <a onclick=\"location.href = location.origin\">\n			RESTART APPLICATION\n		</a>\n	</div>\n\n    <div class=\"logs\">\n" + ((stack1 = helpers.each.call(depth0, depth0 != null ? depth0.buttons : depth0, { "name": "each", "hash": {}, "fn": container.program(3, data, 1, blockParams), "inverse": container.noop, "data": data, "blockParams": blockParams })) != null ? stack1 : "") + "    </div>\n\n</div>\n\n\n";
        }, "useData": true, "useBlockParams": true });
});