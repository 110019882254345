define('ember-kernel/application-loader/resolver/base-resolver', ['exports', './../../kernel/object'], function (exports, _kernelObject) {
  'use strict';

  exports['default'] = _kernelObject['default'].extend({

    order: null,

    init: function init(options) {
      if (options && options.hasOwnProperty('order')) {
        this.order = options.order;
      } else {
        this.order = undefined;
      }
    },

    resolve: function resolve(kernel, previousResult) {
      return RSVP.resolve();
    }

  });
});