define("ember-kernel/application-loader/resolver/browser-resolver", ["exports", "./base-resolver"], function (exports, _baseResolver) {
  "use strict";

  function UnsupportedBrowserError() {
    var message = arguments.length <= 0 || arguments[0] === undefined ? "Unsupported browser" : arguments[0];

    this.name = "UnsupportedBrowserError";
    this.message = message;
    this.stack = new Error().stack;
  }

  UnsupportedBrowserError.prototype = Object.create(Error.prototype);
  UnsupportedBrowserError.prototype.constructor = UnsupportedBrowserError;

  var UnsupportedBrowserError = UnsupportedBrowserError;

  exports.UnsupportedBrowserError = UnsupportedBrowserError;

  function MobileDeviceError() {
    var message = arguments.length <= 0 || arguments[0] === undefined ? "Unsupported Mobile Device" : arguments[0];

    this.name = "MobileDeviceError";
    this.message = message;
    this.stack = new Error().stack;
  }

  MobileDeviceError.prototype = Object.create(Error.prototype);
  MobileDeviceError.prototype.constructor = MobileDeviceError;

  var MobileDeviceError = MobileDeviceError;

  exports.MobileDeviceError = MobileDeviceError;
  exports["default"] = _baseResolver["default"].extend({

    resolve: function resolve() {
      return new RSVP.Promise((function (resolve, reject) {

        switch (bowser.name) {
          case "Chromium":
          case "Chrome":
            if (bowser.version < 52) {
              throw new UnsupportedBrowserError();
            } else if (bowser.mobile) {
              throw new MobileDeviceError();
            } else {
              resolve();
            }
            break;

          default:
            throw new UnsupportedBrowserError();
        }
      }).bind(this));
    }

  });
});