define('ember-kernel/config-manager/resolver/deployment-config-resolver', ['exports', './../../application-loader/resolver/json-resolver'], function (exports, _applicationLoaderResolverJsonResolver) {
  'use strict';

  exports['default'] = _applicationLoaderResolverJsonResolver['default'].extend({

    name: null,

    resolve: function resolve(kernel) {
      var url = this.url;
      return _applicationLoaderResolverJsonResolver['default'].prototype.resolve.apply(this, arguments)['catch'](function (e) {
        if (e.notFound) {
          console.warn('Deployment config file on url ' + url + ' not resolved.');
          return;
        }

        if (e.syntaxError) {
          console.error('Deployment config file on url ' + url + ' resolved, but its not in valid JSON.');
          return;
        }

        console.error('Deployment config file on url ' + url + ' not resolved.', e);
      });
    }

  });
});