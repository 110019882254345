define('ember-kernel/error-manager/handler/window-handler', ['exports', './base-handler'], function (exports, _baseHandler) {
  'use strict';

  exports['default'] = _baseHandler['default'].extend({

    register: function register(manager) {

      var parent = window.onerror;

      window.onerror = function (message, file, line, column, error) {
        if (!error) {
          error = {
            message: message,
            name: error,
            stack: message + ' at ' + "\n" + file + ':' + line + ':' + column
          };
        }
        manager.handleError(error, {
          source: 'window'
        });

        if (parent) {
          return parent.apply(window, arguments);
        } else {
          return true;
        }
      };
    }

  });
});