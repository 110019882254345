define('ember-kernel/application-loader/resolver/webfont-resolver', ['exports', './base-resolver'], function (exports, _baseResolver) {
    'use strict';

    exports['default'] = _baseResolver['default'].extend({

        init: function init(options) {
            _baseResolver['default'].superclass.prototype.init.apply(this, arguments);

            if (!options.family) {
                throw new Error('please define font family to preload');
            }

            this.family = Array.isArray(options.family) ? options.family : [options.family];
            this.testString = this.createTestString(options.testString);
        },

        createTestString: function createTestString(test) {
            var result = {};

            if (test) {
                result[this.family] = test || 'ABCDabcd0123';
            }

            return result;
        },

        resolve: function resolve(kernel, previousResult) {
            return new RSVP.Promise((function (resolve, reject) {

                WebFont.load({
                    custom: {
                        families: this.family,
                        testStrings: this.testString
                    },

                    active: resolve,

                    inactive: (function () {
                        reject(new Error('Cannot load font family \'' + this.family + '\''));
                    }).bind(this)

                });
            }).bind(this));
        }

    });
});