define('ember-kernel/error-manager/consumer/base-consumer', ['exports', './../../kernel/object', './../loggable-error'], function (exports, _kernelObject, _loggableError) {
  'use strict';

  exports['default'] = _kernelObject['default'].extend({

    consumeNative: function consumeNative(error) {
      var loggableError = _loggableError['default'].create({ error: error });
      this.consume(loggableError);
    },

    consume: function consume(loggableError) {}

  });
});