define('ember-kernel/error-manager/consumer/wsod-consumer/wsod-consumer', ['exports', './../base-consumer', './error-page.hbs'], function (exports, _baseConsumer, _errorPageHbs) {
  'use strict';

  exports['default'] = _baseConsumer['default'].extend({

    getTemplate: function getTemplate() {
      return _errorPageHbs['default'];
    },

    parseError: function parseError(error) {
      return {
        description: error.getDescription(),
        message: error.getPlainMessage(),
        stackTrace: error.getPlainText().replace(new RegExp("\n", 'g'), '<br />')
      };
    },

    consume: function consume(error) {
      if (!this.rendered) {
        var template = this.getTemplate();
        var data = this.parseError(error);
        $('body').html(template(data));
        this.rendered = true;
      }
      return true;
    }

  });
});