define('ember-kernel/application-loader/renderer/abstract-renderer', ['exports', './../../kernel/object'], function (exports, _kernelObject) {
  'use strict';

  var AbstractRenderer = _kernelObject['default'].extend({

    showLoader: function showLoader(kernel) {
      throw new Error('Not implemented');
    },

    showLoaderText: function showLoaderText(text, kernel) {
      throw new Error('Not implemented');
    },

    hideLoader: function hideLoader(duration) {
      throw new Error('Not implemented');
    }

  });

  exports.AbstractRenderer = AbstractRenderer;
  exports['default'] = AbstractRenderer;
});