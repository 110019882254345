define('ember-kernel/config-manager/resolver/ember-config-resolver', ['exports', './../../application-loader/resolver/base-resolver'], function (exports, _applicationLoaderResolverBaseResolver) {
  'use strict';

  exports['default'] = _applicationLoaderResolverBaseResolver['default'].extend({

    name: null,

    init: function init(options) {
      _applicationLoaderResolverBaseResolver['default'].superclass.prototype.init.apply(this, arguments);

      options = options || {};
      if (!options.name) {
        throw new Error('meta name must be specified');
      }
    },

    resolveMeta: function resolveMeta(kernel) {
      var name = this.name;
      var selector = 'meta[name="' + this.name + '"]';
      var meta = $(selector);
      if (!meta.length) {
        throw new Error('Config element ' + selector + ' not found');
      }
      var config = JSON.parse(unescape(meta.attr('content')));
      return RSVP.resolve(config);
    },

    resolve: function resolve(kernel, previousConfig) {
      return RSVP.resolve().then(this.resolveMeta.bind(this, kernel));
    }

  });
});