define('ember-kernel/config-manager/resolver/chain-config-resolver', ['exports', './../../application-loader/resolver/base-resolver', './../../tools/property'], function (exports, _applicationLoaderResolverBaseResolver, _toolsProperty) {
  'use strict';

  exports['default'] = _applicationLoaderResolverBaseResolver['default'].extend({

    chain: null,

    init: function init(options) {
      _applicationLoaderResolverBaseResolver['default'].superclass.prototype.init.apply(this, arguments);

      if (options.hasOwnProperty('chain')) {
        this.chain = options.chain;
      } else {
        this.chain = [];
      }
    },

    mergeConfigs: function mergeConfigs(kernel, config, resolvedConfig) {
      for (var k in resolvedConfig) {
        if (resolvedConfig.hasOwnProperty(k)) {
          (0, _toolsProperty.setValue)(config, k, resolvedConfig[k]);
        }
      }
      return config;
    },

    resolve: function resolve(kernel) {
      var p = RSVP.resolve({});
      var config = {};

      this.chain.forEach((function (r) {
        p = p.then(r.resolve.bind(r, kernel, config));
        p = p.then(this.mergeConfigs.bind(this, kernel, config));
      }).bind(this));

      p = p.then((function (config) {
        kernel.triggerKernelEvent('config-manager:resolved', config);
      }).bind(this));

      return p;
    }

  });
});