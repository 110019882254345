define('ember-kernel/kernel-tests', ['exports', './kernel/kernel', './error-manager/error-manager', './application-loader/application-loader', './application-loader/renderer/webrtc-renderer/webrtc-renderer', './application-loader/resolver/javascript-resolver', 'handlebars.runtime', './application-loader/resolver/stylesheet-resolver', './application-loader/resolver/browser-resolver', './application-loader/resolver/webfont-resolver', './config-manager/config-manager', './config-manager/resolver/chain-config-resolver', './config-manager/resolver/ember-config-resolver', './error-manager/consumer/console-consumer/console-consumer', './error-manager/consumer/wsod-consumer/wsod-consumer', './error-manager/consumer/production-consumer/production-consumer'], function (exports, _kernelKernel, _errorManagerErrorManager, _applicationLoaderApplicationLoader, _applicationLoaderRendererWebrtcRendererWebrtcRenderer, _applicationLoaderResolverJavascriptResolver, _handlebarsRuntime, _applicationLoaderResolverStylesheetResolver, _applicationLoaderResolverBrowserResolver, _applicationLoaderResolverWebfontResolver, _configManagerConfigManager, _configManagerResolverChainConfigResolver, _configManagerResolverEmberConfigResolver, _errorManagerConsumerConsoleConsumerConsoleConsumer, _errorManagerConsumerWsodConsumerWsodConsumer, _errorManagerConsumerProductionConsumerProductionConsumer) {
    'use strict';

    var Kernel = _kernelKernel['default'].extend({
        name: 'PhoneKernel',
        modules: {
            'errors-manager': _errorManagerErrorManager['default'].create(),
            'config-manager': _configManagerConfigManager['default'].create(),

            'loader': _applicationLoaderApplicationLoader['default'].create({
                resolvers: [_configManagerResolverChainConfigResolver['default'].create({
                    order: 1,
                    chain: [_configManagerResolverEmberConfigResolver['default'].create({
                        name: 'phone/app/config/environment'
                    })]
                }), _applicationLoaderResolverBrowserResolver['default'].create({
                    order: 2
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    url: 'assets/vendor.js'
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    url: 'assets/test-support.js'
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    url: 'assets/phone.js'
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    url: 'assets/tests.js'
                }), _applicationLoaderResolverStylesheetResolver['default'].create({
                    url: 'assets/vendor.css'
                }), _applicationLoaderResolverStylesheetResolver['default'].create({
                    url: 'assets/test-support.css'
                }), _applicationLoaderResolverStylesheetResolver['default'].create({
                    url: 'assets/phone.css'
                })]
            })
        }

    }).create().run();
});