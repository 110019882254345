define('ember-kernel/error-manager/handler/base-handler', ['exports', './../../kernel/object'], function (exports, _kernelObject) {
  'use strict';

  exports['default'] = _kernelObject['default'].extend({

    register: function register(manager, kernel) {},

    unregister: function unregister(manager) {}

  });
});