define('ember-kernel/application-loader/resolver/json-resolver', ['exports', './base-resolver'], function (exports, _baseResolver) {
  'use strict';

  exports['default'] = _baseResolver['default'].extend({

    url: null,

    init: function init(options) {
      _baseResolver['default'].superclass.prototype.init.apply(this, arguments);

      options = options || {};
      if (!options.url) {
        throw new Error('url must be specified');
      }
      this.url = options.url;
    },

    resolve: function resolve(kernel, previousResult) {
      return new RSVP.Promise((function (doResolve, doReject) {
        $.ajax({
          url: this.url
        }).then((function (config) {
          doResolve(config);
        }).bind(this)).fail((function (jqXHR, textStatus, errorThrown) {
          var error = new Error('Cannot load file ' + this.url);

          if (errorThrown instanceof SyntaxError) {
            error.syntaxError = true;
          }

          if (jqXHR.status === 404) {
            error.notFound = true;
          }

          error.textStatus = textStatus;
          error.errorThrown = errorThrown;
          error.jqXHR = jqXHR;

          doReject(error);
        }).bind(this));
      }).bind(this));
    }

  });
});