define('ember-kernel/application-loader/resolver/element-resolver', ['exports', './base-resolver', './../../tools/hash'], function (exports, _baseResolver, _toolsHash) {
  'use strict';

  exports['default'] = _baseResolver['default'].extend({

    url: null,

    version: null,

    init: function init(options) {
      _baseResolver['default'].superclass.prototype.init.apply(this, arguments);

      if (!options.url) {
        throw new Error('Please specify url');
      }
    },

    createElement: function createElement(appVersion) {
      throw new Error('Implement me');
    },

    generatePostfix: function generatePostfix(kernel) {
      this.postFix = '';

      var version = kernel.config.APP.version;
      if (version) {
        this.postFix = '?' + (version.length < 8 ? version : _toolsHash.AlphabeticID.encode((0, _toolsHash.hash)(version)));
      }
    },

    resolve: function resolve(kernel, previousResult) {

      var url = this.url;

      return new RSVP.Promise((function (resolve, reject) {
        this.generatePostfix(kernel);

        var ele = this.createElement();

        ele.async = false;

        ele.defer = true;

        ele.onerror = function () {
          reject(new Error('Loading of resource \'' + url + '\' failed'));
        };

        ele.onload = function () {
          resolve(ele);
        };

        var head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(ele);

        return ele;
      }).bind(this));
    }
  });
});