define('ember-kernel/error-manager/loggable-error', ['exports', './../kernel/object'], function (exports, _kernelObject) {
    'use strict';

    var LoggableError = _kernelObject['default'].extend({

        error: null,

        getButtonsForErrorPage: function getButtonsForErrorPage() {
            if (!this.buttonsForErrorPage) {
                this.buttonsForErrorPage = [];
            }
            return this.buttonsForErrorPage;
        },

        addButtonForErrorPage: function addButtonForErrorPage(text, handler) {
            this.getButtonsForErrorPage().push({ text: text, handler: handler, id: Math.ceil(Math.random() * 10000) });
        },

        getPlainMessage: function getPlainMessage() {
            if (this.error && this.error.message) {
                return this.error.message;
            } else {
                return 'With no specific details';
            }
        },

        extractAdditionalData: function extractAdditionalData() {

            var names = {};

            var getErrorName = function getErrorName(error) {
                var name = error.name || 'Error';
                if (!names[name]) {
                    names[name] = 1;
                } else {
                    names[name]++;
                }
                return name + ':' + names[name];
            };

            var extract = (function (error, additionalDataHash) {
                if (!error || !error.advancedException) {
                    return null;
                }

                var name = getErrorName(error);

                if (error.additionalData) {
                    additionalDataHash = additionalDataHash || {};
                    additionalDataHash[name] = {};
                    error.additionalData.forEach((function (data) {
                        this.flatizeAdditionalData(data, null, additionalDataHash[name]);
                    }).bind(this));
                }

                extract(error.previous, additionalDataHash);

                return additionalDataHash;
            }).bind(this);

            var result = undefined;
            try {
                result = extract(this.error);
            } catch (e) {
                console.error('Cannot extract additional data', e.stack);
            }

            return result;
        },

        getDescription: function getDescription() {
            if (this.error && this.error.description) {
                return this.error.description;
            } else {
                return null;
            }
        },

        getPlainText: function getPlainText() {
            var error = this.error;

            if (error) {
                var name = error.name || 'Error: ' + error.toString();
                var message = error.message ? ' (' + error.message + ') ' : '';
                var stack = error.stack || '';

                stack = stack.substring(stack.indexOf("\n") + 1);

                return name + message + "\n" + stack;
            } else {
                return 'Unspecified error';
            }
        },

        flatizeAdditionalData: function flatizeAdditionalData(data) {
            var path = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
            var target = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
            var processed = arguments.length <= 3 || arguments[3] === undefined ? [] : arguments[3];

            if (typeof path === 'number') {
                path = String(path);
            }

            if (!data) {
                if (!path) {
                    target = data;
                } else {
                    target[path] = data;
                }
                return;
            }

            if (typeof data === 'object' && processed.indexOf(data) !== -1) {
                return;
            } else {
                processed.push(data);
            }

            if (typeof data.forEach === 'function') {
                data.forEach((function (val, key) {
                    var currentPath = path ? path + '[' + key + ']' : key;
                    this.flatizeAdditionalData(val, currentPath, target, processed);
                }).bind(this));
                return;
            }

            var Em = window['Ember'];
            if (Em && data instanceof Em.Object) {
                for (var key in data) {
                    if (data[key] instanceof Em.ComputedProperty) {
                        var currentPath = path ? path + '.' + key : key;

                        try {
                            this.flatizeAdditionalData(data.get(key), currentPath, target, processed);
                        } catch (e) {}
                    }
                }
                return;
            }

            if (typeof data === 'object') {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        var currentPath = path ? path + '.' + key : key;
                        this.flatizeAdditionalData(data[key], currentPath, target, processed);
                    }
                }
                return;
            }

            if (typeof data !== 'function') {
                target[path] = data;
                return;
            }
        }

    });

    exports['default'] = LoggableError;
});