define('ember-kernel/application-loader/resolver/webrtc-version-maintainer-resolver', ['exports', 'ember-kernel/application-loader/resolver/version-maintainer-resolver'], function (exports, _emberKernelApplicationLoaderResolverVersionMaintainerResolver) {
    'use strict';

    exports['default'] = _emberKernelApplicationLoaderResolverVersionMaintainerResolver['default'].extend({

        resolve: function resolve(kernel, previousResult) {
            var name, version, config, wl_version, wl, wl_bn, dsk_version, dsk_source_version, dsk, dsk_platform, dsk_bn;

            try {
                config = kernel.config;
                name = kernel.config.APP.name;
                version = kernel.config.APP.version;
            } catch (e) {}

            try {
                wl_version = config.APP.version_whitelabel;
                wl_bn = config.APP.version_whitelabel_build_number;
                if (wl_version) {
                    wl = config.APP.version_whitelabel_str = wl_version + ('-b' + wl_bn);
                }
            } catch (e) {}

            try {
                if (window.electronInfo) {
                    dsk_version = kernel.config.APP.version_desktop_desktop = window.electronInfo.version;
                    dsk_source_version = kernel.config.APP.version_desktop_source = window.electronInfo.sourceVersion;
                    dsk_platform = kernel.config.APP.version_desktop_platform = window.navigator.platform;
                    dsk_bn = kernel.config.APP.version_desktop_build_number = window.electronInfo.buildNumber;

                    if (dsk_version) {
                        dsk = kernel.config.APP.version_desktop_str = dsk_version + ('-b' + dsk_bn + '-' + dsk_platform);
                    }
                }
            } catch (e) {}

            console.debug('Welcome in: \'' + name + '\' version: \'' + version + '\' whitelabel: \'' + (wl || 'none') + '\' desktop source version: \'' + (dsk_source_version || 'none') + '\' desktop executable version: \'' + (dsk || 'none') + '\' platform: \'' + window.navigator.platform + '\' ');

            var errorManager = kernel.getModule('errors-manager');
            if (errorManager && errorManager.sentryConsumer) {
                errorManager.sentryConsumer.storeAppContext({
                    app: name,
                    version: version,
                    platform: window.navigator.platform,
                    version_whitelabel: wl_version,
                    version_whitelabel_str: wl,
                    version_whitelabel_build_number: wl_bn,
                    version_desktop: dsk_version,
                    version_desktop_source: dsk_source_version,
                    version_desktop_build_number: dsk_bn,
                    version_desktop_platform: dsk_platform,
                    version_desktop_str: dsk
                });
            }

            return RSVP.resolve(previousResult);
        }
    });
});