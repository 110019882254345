define('ember-kernel/kernel/object', ['exports'], function (exports) {
    'use strict';

    function construct(constructor, args) {
        function KernelObject() {
            return constructor.apply(this, args);
        }

        KernelObject.prototype = constructor.prototype;
        var instance = new KernelObject();

        instance.constructor = constructor;
        instance['class'] = constructor;
        instance.superclass = constructor.superclass;

        return instance;
    }

    var create = function create() {
        var args = Array.prototype.slice.call(arguments);
        return construct(this, args);
    };

    var extend = function extend() {
        var constructor = function constructor() {
            for (var i = 0; i < arguments.length; i++) {
                var extension = arguments[i];

                if (!(extension instanceof Object)) {
                    throw new Error('Only Objects could be passed to constryuctor');
                }

                for (var n in extension) {
                    if (extension.hasOwnProperty(n)) {
                        this[n] = extension[n];
                    }
                }
            }
            if (this.init) {
                if (typeof this.init === 'function') {
                    this.init.apply(this, arguments);
                } else {
                    throw new Error('init hook must be function');
                }
            }
        };

        var superprototype = this.prototype;
        var prototype = Object.create(superprototype);
        for (var i = 0; i < arguments.length; i++) {
            var extension = arguments[i];
            for (var n in extension) {
                if (extension.hasOwnProperty(n)) {
                    prototype[n] = extension[n];
                }
            }
        }

        constructor.superclass = this;
        constructor.prototype = prototype;
        constructor.prototype.constructor = prototype.constructor || constructor;
        constructor.extend = extend;
        constructor.create = create;

        return constructor;
    };

    var BaseObject = extend.apply(function () {});

    BaseObject.prototype.init = function () {};

    exports['default'] = BaseObject;
});