define('ember-kernel/config-manager/config-manager', ['exports', './../kernel/base-module'], function (exports, _kernelBaseModule) {
  'use strict';

  exports['default'] = _kernelBaseModule['default'].extend({

    config: null,

    init: function init(options) {
      options = options || {};
      this.config = options.config || {};
    },

    load: function load(kernel) {
      kernel.config = this.config;
    },

    onKernelEvent: function onKernelEvent(event, data) {
      if (event === 'config-manager:resolved') {
        data = data || {};
        for (var k in data) {
          if (data.hasOwnProperty(k)) {
            this.config[k] = data[k];
          }
        }
      }
    }

  });
});