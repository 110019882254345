define('ember-kernel/error-manager/consumer/production-consumer/production-consumer', ['exports', './../base-consumer', './style.hbs', './production-error-page.hbs', './production-unsupported-browser.hbs', './production-mobile-device.hbs', './../../../application-loader/resolver/browser-resolver'], function (exports, _baseConsumer, _styleHbs, _productionErrorPageHbs, _productionUnsupportedBrowserHbs, _productionMobileDeviceHbs, _applicationLoaderResolverBrowserResolver) {
    'use strict';

    exports['default'] = _baseConsumer['default'].extend({

        getTemplate: function getTemplate(error) {
            if (error.error instanceof _applicationLoaderResolverBrowserResolver.UnsupportedBrowserError) {
                return _productionUnsupportedBrowserHbs['default'];
            } else if (error.error instanceof _applicationLoaderResolverBrowserResolver.MobileDeviceError) {
                return _productionMobileDeviceHbs['default'];
            } else {
                return _productionErrorPageHbs['default'];
            }
        },

        consume: function consume(error, kernel) {
            if (!this.rendered) {
                var template = this.getTemplate(error);
                var data = {
                    resources: kernel.resources,
                    buttons: error.getButtonsForErrorPage(),
                    description: error.getDescription(),
                    message: error.getPlainMessage()
                };

                $('head').append((0, _styleHbs['default'])());
                $('body').html(template(data));

                error.getButtonsForErrorPage().forEach(function (button) {
                    $('#' + button.id).click(button.handler);
                });

                this.rendered = true;
            }
            return true;
        }

    });
});