define('ember-kernel/kernel/base-module', ['exports', './object'], function (exports, _object) {
  'use strict';

  exports['default'] = _object['default'].extend({

    onKernelEvent: function onKernelEvent(name, data) {},

    load: function load() {
      throw new Error('Implement me');
    }

  });
});