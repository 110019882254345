define('ember-kernel/application-loader/resolver/version-maintainer-resolver', ['exports', './base-resolver'], function (exports, _baseResolver) {
  'use strict';

  exports['default'] = _baseResolver['default'].extend({

    order: null,

    resolve: function resolve(kernel, previousResult) {
      var name, version, config;

      try {
        config = kernel.config;
        name = kernel.config.APP.name;
        version = kernel.config.APP.version;
      } catch (e) {}

      console.debug('Welcome in: \'' + name + '\' version: \'' + version + '\'');

      var errorManager = kernel.getModule('errors-manager');
      if (errorManager && errorManager.sentryConsumer) {
        errorManager.sentryConsumer.storeAppContext({
          app: name,
          version: version
        });
      }

      return RSVP.resolve(previousResult);
    }

  });
});