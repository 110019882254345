define('ember-kernel/error-manager/error-manager', ['exports', './../kernel/base-module', './handler/rsvp-handler', './consumer/sentry-consumer/sentry-consumer', './handler/window-handler', './loggable-error'], function (exports, _kernelBaseModule, _handlerRsvpHandler, _consumerSentryConsumerSentryConsumer, _handlerWindowHandler, _loggableError) {
  'use strict';

  var ErrorManager = _kernelBaseModule['default'].extend({

    config: null,

    defaultConsumers: [],

    environmentConsumers: [],

    consumers: [],

    sentryConsumer: null,

    unloading: false,

    kernel: null,

    init: function init() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      _kernelBaseModule['default'].superclass.prototype.init.apply(this, arguments);

      this.addDefaultHandlers();
      this.addDefaultConsumers();

      setTimeout(function () {
        $(window).on('beforeunload', function () {
          this.unloading = true;
        });
      }, 2000);
    },

    load: function load(kernel) {
      this.kernel = kernel;
      this.registerAll(kernel);
    },

    addDefaultHandlers: function addDefaultHandlers() {
      this.handlers = [_handlerWindowHandler['default'].create(), _handlerRsvpHandler['default'].create()];
    },

    addDefaultConsumers: function addDefaultConsumers() {
      var def = this.defaultConsumers;
      if (Array.isArray(def)) {
        for (var i = 0; i < def.length; i++) {
          var Consumer = def[i];
          this.consumers.push(Consumer.create());
        }
      }
    },

    registerAll: function registerAll(kernel) {
      for (var i = 0; i < this.handlers.length; i++) {
        var h = this.handlers[i];
        h.register(this, kernel);
      }
    },

    registerHandler: function registerHandler(handler) {
      this.handlers.push(handler);
      handler.register(this);
    },

    unregisterAll: function unregisterAll() {
      for (var i = 0; i < this.handlers.length; i++) {
        var h = this.handlers[i];
        h.unregister(this);
      }
    },

    handleError: function handleError(error, options) {
      if (!this.unloading) {
        var e = _loggableError['default'].create({
          error: error,
          options: options
        });
        if (this.consumers.length) {
          for (var i = 0; i < this.consumers.length; i++) {
            try {
              if (!this.consumers[i].consume(e, this.kernel)) {
                break;
              }
            } catch (err) {
              console.error('Error during consuming error', this.consumers[i], err.stack);
            }
          }
        } else {
          console.error(error);
        }
      } else {
        console.error('Page is about to unload, suppressed error:', error);
      }
    },

    configResolved: false,

    onKernelEvent: function onKernelEvent(event, data) {
      if (event === 'config-manager:resolved' && !this.configResolved) {
        this.configResolved = true;
        this.config = data;

        if (this.config.sentry_enable) {
          this.sentryConsumer = _consumerSentryConsumerSentryConsumer['default'].create({
            'sentryUrl': this.config.sentry_url
          });
          this.consumers.push(this.sentryConsumer);
        }

        var def = this.environmentConsumers[this.config.environment];
        if (Array.isArray(def)) {
          for (var i = 0; i < def.length; i++) {
            var Consumer = def[i];
            this.consumers.push(Consumer.create());
          }
        }
      }
    },

    insertConsumer: function insertConsumer(consumer) {
      this.consumers.splice(0, 0, consumer);
    }

  });

  exports['default'] = ErrorManager;
});