define('ember-kernel/tools/hash', ['exports'], function (exports) {
    'use strict';

    var hash = function hash(str) {
        var hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (var i = 0; i < str.length; i++) {
            var char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    };

    exports.hash = hash;
    var AlphabeticID = {
        index: 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',

        reverse: function reverse(str) {
            return str.split('').reverse().join('');
        },

        encode: function encode(_number) {
            if ('undefined' === typeof _number) {
                return null;
            } else if ('number' !== typeof _number) {
                throw new Error('Wrong parameter type');
            }

            _number = Math.abs(_number);

            var ret = '';

            for (var i = Math.floor(Math.log(parseInt(_number)) / Math.log(AlphabeticID.index.length)); i >= 0; i--) {
                ret = ret + AlphabeticID.index.substr(Math.floor(parseInt(_number) / AlphabeticID.bcpow(AlphabeticID.index.length, i)) % AlphabeticID.index.length, 1);
            }

            return this.reverse(ret);
        },

        decode: function decode(_string) {
            if ('undefined' === typeof _string) {
                return null;
            } else if ('string' !== typeof _string) {
                throw new Error('Wrong parameter type');
            }

            var str = this.reverse(_string);
            var ret = 0;

            for (var i = 0; i <= str.length - 1; i++) {
                ret = ret + AlphabeticID.index.indexOf(str.substr(i, 1)) * AlphabeticID.bcpow(AlphabeticID.index.length, str.length - 1 - i);
            }

            return ret;
        },

        bcpow: function bcpow(_a, _b) {
            return Math.floor(Math.pow(parseFloat(_a), parseInt(_b)));
        }
    };
    exports.AlphabeticID = AlphabeticID;
});