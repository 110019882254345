define('ember-kernel/kernel-app', ['exports', './kernel/kernel', './error-manager/error-manager', './application-loader/application-loader', './application-loader/renderer/webrtc-renderer/webrtc-renderer', './application-loader/resolver/javascript-resolver', 'handlebars.runtime', './application-loader/resolver/stylesheet-resolver', './application-loader/resolver/browser-resolver', './application-loader/resolver/webfont-resolver', './application-loader/resolver/webrtc-version-maintainer-resolver', './config-manager/config-manager', './config-manager/resolver/chain-config-resolver', './config-manager/resolver/ember-config-resolver', './config-manager/resolver/deployment-config-resolver', './error-manager/consumer/console-consumer/console-consumer', './error-manager/consumer/wsod-consumer/wsod-consumer', './error-manager/consumer/production-consumer/production-consumer'], function (exports, _kernelKernel, _errorManagerErrorManager, _applicationLoaderApplicationLoader, _applicationLoaderRendererWebrtcRendererWebrtcRenderer, _applicationLoaderResolverJavascriptResolver, _handlebarsRuntime, _applicationLoaderResolverStylesheetResolver, _applicationLoaderResolverBrowserResolver, _applicationLoaderResolverWebfontResolver, _applicationLoaderResolverWebrtcVersionMaintainerResolver, _configManagerConfigManager, _configManagerResolverChainConfigResolver, _configManagerResolverEmberConfigResolver, _configManagerResolverDeploymentConfigResolver, _errorManagerConsumerConsoleConsumerConsoleConsumer, _errorManagerConsumerWsodConsumerWsodConsumer, _errorManagerConsumerProductionConsumerProductionConsumer) {
    'use strict';

    var Kernel = _kernelKernel['default'].extend({
        resourcesUrl: '/assets/kernel-app.js.resources.json',
        name: 'PhoneKernel',
        modules: {
            'errors-manager': _errorManagerErrorManager['default'].create({
                "defaultConsumers": [_errorManagerConsumerConsoleConsumerConsoleConsumer['default']],
                "environmentConsumers": {
                    "production": [_errorManagerConsumerProductionConsumerProductionConsumer['default']],
                    "development": [_errorManagerConsumerWsodConsumerWsodConsumer['default']]
                }
            }),
            'config-manager': _configManagerConfigManager['default'].create(),
            'loader': _applicationLoaderApplicationLoader['default'].create({
                hideSplashOnLoaded: false,
                renderer: _applicationLoaderRendererWebrtcRendererWebrtcRenderer['default'].create(),
                resolvers: [_configManagerResolverChainConfigResolver['default'].create({
                    order: 10,
                    chain: [_configManagerResolverEmberConfigResolver['default'].create({
                        name: 'phone/app/config/environment'
                    }), _configManagerResolverDeploymentConfigResolver['default'].create({
                        url: '/deployment.json'
                    })]
                }), _applicationLoaderResolverWebrtcVersionMaintainerResolver['default'].create({
                    order: 30
                }), _applicationLoaderResolverBrowserResolver['default'].create({
                    order: 40
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    url: 'assets/vendor.js'
                }), _applicationLoaderResolverStylesheetResolver['default'].create({
                    url: 'assets/vendor.css'
                }), _applicationLoaderResolverStylesheetResolver['default'].create({
                    url: 'assets/phone.css'
                }), _applicationLoaderResolverWebfontResolver['default'].create({
                    order: 1000,
                    family: 'Material-Design-Icons',
                    testString: ''
                }), _applicationLoaderResolverWebfontResolver['default'].create({
                    order: 2000,
                    family: 'Source Sans Pro'
                }), _applicationLoaderResolverWebfontResolver['default'].create({
                    order: 1000,
                    family: 'Glyphicons Halflings',
                    testString: ''
                }), _applicationLoaderResolverJavascriptResolver['default'].create({
                    order: 9999,
                    url: 'assets/phone.js'
                })]
            })
        }

    }).create().run();
});