define('ember-kernel/error-manager/handler/rsvp-handler', ['exports', './base-handler'], function (exports, _baseHandler) {
  'use strict';

  exports['default'] = _baseHandler['default'].extend({

    register: function register(manager) {
      RSVP.configure('onerror', function (error) {
        manager.handleError(error, {
          source: 'rsvp'
        });
      });
    }

  });
});