define('ember-kernel/application-loader/application-loader', ['exports', './../kernel/base-module'], function (exports, _kernelBaseModule) {
  'use strict';

  exports['default'] = _kernelBaseModule['default'].extend({

    resolvers: null,

    hideSplashOnLoaded: true,

    init: function init(options) {
      if (!options.resolvers || !Array.isArray(options.resolvers)) {
        throw new Error('please specify array of resolvers');
      }

      this.hideSplashOnLoaded = typeof options.hideSplashOnLoaded === 'undefined' ? this.hideSplashOnLoaded : options.hideSplashOnLoaded;
    },

    toResolveCount: 0,
    resolvedCount: 0,

    renderProgress: function renderProgress() {
      var progress = Math.round(100 / this.toResolveCount * this.resolvedCount);
      this.updateSplashText('Loading ' + progress + '%');
    },

    updateSplashText: function updateSplashText(text) {
      if (this.renderer) {
        this.renderer.showLoaderText(text);
      }
    },

    showSplash: function showSplash(kernel) {
      if (this.renderer) {
        this.renderer.showLoader(kernel);
        this.renderer.showLoaderText('Loading...', kernel);
      }
    },

    hideSplash: function hideSplash() {
      if (this.renderer) {
        this.renderer.hideLoader();
      }
    },

    load: function load(kernel) {
      this.showSplash(kernel);

      this.toResolveCount = this.resolvers.length;
      this.resolvedCount = 0;

      var preResolvers = {};
      var postResolvers = {};
      var stdResolvers = [];

      var serialResolvers = preResolvers;
      this.resolvers.forEach(function (resolver) {
        var order = resolver.order;
        if (order) {
          serialResolvers[order] = serialResolvers[order] || [];
          serialResolvers[order].push(resolver);
        } else {
          serialResolvers = postResolvers;
          stdResolvers.push(resolver);
        }
      });

      return RSVP.resolve().then((function () {
        return this.serialResolve(kernel, preResolvers);
      }).bind(this)).then((function () {
        return this.parallelResolve(kernel, stdResolvers);
      }).bind(this)).then((function () {
        return this.serialResolve(kernel, postResolvers);
      }).bind(this)).then((function () {
        if (this.hideSplashOnLoaded) {
          this.hideSplash();
        }
      }).bind(this));
    },

    parallelResolve: function parallelResolve(kernel, resolvers) {
      return RSVP.all(resolvers.map((function (resolver) {
        return resolver.resolve(kernel)['finally']((function () {
          this.resolvedCount++;
          this.renderProgress(kernel);
        }).bind(this));
      }).bind(this)));
    },

    serialResolve: function serialResolve(kernel, resolvers) {
      var promise = RSVP.resolve();

      Object.keys(resolvers).sort(function (a, b) {
        return a.order - b.order;
      }).forEach((function (key) {
        promise = promise.then((function () {
          return this.parallelResolve(kernel, resolvers[key]);
        }).bind(this));
      }).bind(this));

      return promise;
    }

  });
});