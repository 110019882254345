define('ember-kernel/application-loader/renderer/standard-renderer/standard-renderer', ['exports', './../abstract-renderer', './standard-renderer.hbs', './style.hbs'], function (exports, _abstractRenderer, _standardRendererHbs, _styleHbs) {
  'use strict';

  exports['default'] = _abstractRenderer['default'].extend({

    showLoader: function showLoader(kernel) {
      $('head').append((0, _styleHbs['default'])({ resources: kernel.resources }));
      $('body').append((0, _standardRendererHbs['default'])({ resources: kernel.resources }));
      $('.kernel-loading-page').fadeIn(500);
    },

    showLoaderText: function showLoaderText(text, kernel) {},

    hideLoader: function hideLoader(duration) {
      $(".kernel-loading-page").fadeOut(750, function () {
        $(".kernel-loading-style").remove();
        $(".kernel-loading-page").remove();
      });
    }

  });
});